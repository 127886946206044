import axios from "axios";
export const purposeMaster = {
  data() {
    return {
      purposeMastersList: [],
      purposeMastersWithAllList: [],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async getpurposeMasterListMethod() {
      try {
        let data = JSON.stringify({});
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicPurposeMasterRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };
        let result = await axios(config);
        if (
          result.status == 200 &&
          result.data &&
          result.data.status &&
          result.data.status == "Success"
        ) {
          this.purposeMastersList = result.data.data.Items.map(
            (commonObj) => commonObj.purpose_type_name
          );
          for (const purposeMaster of this.purposeMastersList) {
            this.purposeMastersWithAllList.push(purposeMaster);
          }
          this.purposeMastersWithAllList.unshift("All");
        }
      } catch (error) {
        throw error;
      }
    },
    getDynamicPurposeMasterRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com" || window.location.hostname === 'www.dev.commdealdata.com'
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/listpurposetypes"
        : window.location.hostname === "test.commdealdata.com" || window.location.hostname === 'www.test.commdealdata.com' ||
          window.location.hostname == "localhost"
        ? "https://a9fb1td1g1.execute-api.us-east-1.amazonaws.com/test/listpurposetypes"
        : window.location.hostname === "uat.commdealdata.com" || window.location.hostname === 'www.uat.commdealdata.com'
        ? "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/listpurposetypes"
        : "https://401a68okke.execute-api.us-east-1.amazonaws.com/live/listpurposetypes";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com" || window.location.hostname === 'www.dev.commdealdata.com'
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com" || window.location.hostname === 'www.test.commdealdata.com' ||
          window.location.hostname == "localhost"
        ? "xSGhbOtd6g2DYh5PhhOJ77HPFFYAQziDCst97SZ7"
        : window.location.hostname === "uat.commdealdata.com" || window.location.hostname === 'www.uat.commdealdata.com'
        ? "iORDwCSXHl670CUuvVuo10kvpylJw7I7KdBqatWc"
        : "w6QQeRLHshapYVbYT94aX9TCJCl75pc37oW3BrOb";
    },
  },
};
